<template>
  <skeleton-person-general-information-global v-if="bSkeleton" />
    <div v-else>
        <!-- #region CUSTOMER -->
        <!-- #region INFORMACION DE IDENTIFICACION  -->
        <div class="content-primary-text-title-global">
            <p class="primary-text-title-global">
                Información de identificación  
            </p>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Nombre público de la empresa(Alias)</p>
                            <p class="text-information">
                                {{ oCustomerOrigin.sPublicName }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oCustomer.sPublicName" label="Nombre público de la empresa(Alias)"
                            placeholder="Nombre público de la empresa..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined maxlength="50" @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Nombre público de la empresa(Alias)
                                    <span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Correo electrónico de la empresa</p>
                            <p class="text-information">
                                {{ oCustomerOrigin.sEmail }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oCustomer.sEmail" label="Correo electrónico de la empresa"
                            placeholder="Correo electrónico de la empresa..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined maxlength="70" @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Correo electrónico de la empresa
                                    <span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <phone-text-component-global v-if="!bModify"
                            :sCountryCallingCodeOrigin="oCustomerContactOrigin.sCountryCallingCode"
                            :sAreaCallingCodeOrigin="oCustomerContactOrigin.sAreaCallingCode"
                            :sPhoneNumberOrigin="oCustomerContactOrigin.sPhoneNumber"
                            :sPhoneExtensionOrigin="oCustomerContactOrigin.sPhoneExtension" />
                        <phone-component-global v-else :bImportantDate="true"
                            @updateCountryCallingCode="updateCountryCallingCode"
                            :sCountryCallingCodeOrigin="oCustomerContact.sCountryCallingCode"
                            @updateAreaCallingCode="updateAreaCallingCode"
                            :sAreaCallingCodeOrigin="oCustomerContact.sAreaCallingCode"
                            @updatePhoneNumber="updatePhoneNumber" :sPhoneNumberOrigin="oCustomerContact.sPhoneNumber"
                            @updatePhoneExtension="updatePhoneExtension"
                            :sPhoneExtensionOrigin="oCustomerContact.sPhoneExtension" />
                    </v-col>

                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">País de la empresa</p>
                            <p class="text-information">
                                {{ oCustomerAddressCountryOrigin.sCountryName }}
                            </p>
                        </div>
                        <v-select v-else v-model="sCountry" :items="aItemsCountry" item-text="sName"
                            item-value="sCountryId" label="País de la empresa" class="global-select"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            outlined @change="changeCountry(), validateFormCustomer()">
                            <template slot="label">
                                <span>País de la empresa<span class="important-data">*</span></span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Estado de la empresa</p>
                            <p class="text-information">
                                {{ oCustomerAdrressStateOrigin.sStateName }}
                            </p>
                        </div>
                        <v-select v-else v-model="oCustomerAddressState.sStateId" :disabled="bDisabledState"
                            :items="aItemsStates" item-text="sName" item-value="sStateId" label="Estado de la empresa"
                            class="global-select" color="var(--primary-color-border-input)"
                            background-color="var(--primary-color-menu)" outlined
                            @change="changeState(), validateFormCustomer()">
                            <template slot="label">
                                <span>Estado de la empresa<span class="important-data">*</span></span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Ciudad de la empresa</p>
                            <p class="text-information">
                                {{ oCustomerAddressOrigin.sCityName }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oCustomerAddress.sCityName"
                            :disabled="!(oCustomerAddressState.sStateId !== null)" label="Ciudad de la empresa"
                            placeholder="Ciudad de la empresa..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined maxlength="40" @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Ciudad de la empresa<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Código postal de la empresa</p>
                            <p class="text-information">
                                {{ oCustomerAddressOrigin.sZIPCode }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oCustomerAddress.sZIPCode" :disabled="
                            !(
                                oCustomerAddress.sCityName.trim() !== '' &&
                                oCustomerAddressState.sStateId !== null
                            )
                        " label="Código postal de la empresa" placeholder="Código postal..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined maxlength="5" @keyup="validateFormCustomer()"
                            @keypress="fieldNumber($event)" @paste="noLetterZipCodeInformation()">
                            <template slot="label">
                                <span>Código postal de la empresa<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <div v-if="!bModify">
                            <p class="text-title">Dirección de la empresa</p>
                            <p class="text-information">
                                {{ oCustomerAddressOrigin.sAddress }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oCustomerAddress.sAddress" label="Dirección de la empresa"
                            placeholder="Ej: Privada San Pedro..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined maxlength="50" @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Dirección de la empresa<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <div v-if="!bModify">
                            <p class="text-title">Especificación de dirección</p>
                            <p v-if="oCustomerAddressOrigin.sOptionalAddress" class="text-information">
                                {{ oCustomerAddressOrigin.sOptionalAddress }}
                            </p>
                            <p v-else class="text-data-empty">
                                {{ oCustomerAddressOrigin.sOptionalAddress }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oCustomerAddress.sOptionalAddress"
                            label="Especificación de dirección" placeholder="Ej: Piso 4, Loft 1, casa color amarillo..."
                            class="global-text-field" color="var(--primary-color-border-input)"
                            background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="70"
                            @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Especificación de dirección<span class="important-data"></span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider class="divider-global mt-9"></v-divider>
        </div>
        <!-- #endregion INFORMACION DE IDENTIFICACION  -->

        <!-- #region INFORMACION DE ENCARGADO  -->
        <div class="content-primary-text-title-global">
            <p class="primary-text-title-global">
                Información de encargado

            </p>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Nombre del encargado de la empresa</p>
                            <p class="text-information">
                                {{ oManagerInformationOrigin.sFullName }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oManagerInformation.sFullName"
                            label="Nombre del encargado de la empresa"
                            placeholder="Nombre del encargado de la empresa..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined maxlength="50" @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Nombre del encargado de la empresa<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Correo electrónico del encargado</p>
                            <p class="text-information">
                                {{ oManagerInformationOrigin.sEmail }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oManagerInformation.sEmail"
                            label="Correo electrónico del encargado" placeholder="Correo electrónico del encargado..."
                            class="global-text-field" color="var(--primary-color-border-input)"
                            background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="70"
                            @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Correo electrónico del encargado<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <phone-text-component-global v-if="!bModify"
                            :sCountryCallingCodeOrigin="oManagerContactOrigin.sCountryCallingCode"
                            :sAreaCallingCodeOrigin="oManagerContactOrigin.sAreaCallingCode"
                            :sPhoneNumberOrigin="oManagerContactOrigin.sPhoneNumber"
                            :sPhoneExtensionOrigin="oManagerContactOrigin.sPhoneExtension" />
                        <phone-component-global v-else :bImportantDate="true"
                            @updateCountryCallingCode="updateManagerCountryCallingCode"
                            :sCountryCallingCodeOrigin="oManagerContact.sCountryCallingCode"
                            @updateAreaCallingCode="updateManagerAreaCallingCode"
                            :sAreaCallingCodeOrigin="oManagerContact.sAreaCallingCode"
                            @updatePhoneNumber="updateManagerPhoneNumber"
                            :sPhoneNumberOrigin="oManagerContact.sPhoneNumber"
                            @updatePhoneExtension="updateManagerPhoneExtension"
                            :sPhoneExtensionOrigin="oManagerContact.sPhoneExtension" />
                    </v-col>

                </v-row>
            </v-container>
            <v-divider class="divider-global mt-9"></v-divider>
        </div>
        <!-- #endregion INFORMACION DE ENCARGADO  -->

        <!-- #region INFORMACION FISCAL  -->
        <div class="content-primary-text-title-global">
            <p class="primary-text-title-global">
                Información fiscal
            </p>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Nombre real de la empresa</p>
                            <p class="text-information">
                                {{ oTaxInformationOrigin.sBusinessName }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oTaxInformation.sBusinessName" label="Nombre real de la empresa"
                            placeholder="Nombre real de la empresa..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined maxlength="50" @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Nombre real de la empresa<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Correo electrónico de facturación</p>
                            <p class="text-information">
                                {{ oTaxInformationOrigin.sTaxEmail }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oTaxInformation.sTaxEmail"
                            label="Correo electrónico de facturación" placeholder="Correo electrónico de facturación..."
                            class="global-text-field" color="var(--primary-color-border-input)"
                            background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="70"
                            @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Correo electrónico de facturación<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                        <div v-if="!bModify">
                            <p class="text-title">Registro federal de contribuyentes(RFC)</p>
                            <p class="text-information">
                                {{ oTaxInformationOrigin.sTaxId }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oTaxInformation.sTaxId"
                            label="Registro federal de contribuyentes(RFC)"
                            placeholder="Registro federal de contribuyentes(RFC)..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined maxlength="13" @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Registro federal de contribuyentes(RFC)<span
                                        class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">País (facturación)</p>
                            <p class="text-information">
                                {{ oTaxAddressCountryOrigin.sCountryName }}
                            </p>
                        </div>
                        <v-select v-else v-model="sCountryTax" :items="aItemsCountry" item-text="sName"
                            item-value="sCountryId" label="País (facturación)" class="global-select"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            outlined @change="changeCountryTax(), validateFormCustomer()">
                            <template slot="label">
                                <span>País (facturación)<span class="important-data">*</span></span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Estado (facturación)</p>
                            <p class="text-information">
                                {{ oTaxAddressStateOrigin.sStateName }}
                            </p>
                        </div>
                        <v-select v-else v-model="oTaxAddressState.sStateId" :disabled="bDisabledStateTax"
                            :items="aItemsStatesTax" item-text="sName" item-value="sStateId"
                            label="Estado (facturación)" class="global-select" color="var(--primary-color-border-input)"
                            background-color="var(--primary-color-menu)" outlined
                            @change="changeStateTax(), validateFormCustomer()">
                            <template slot="label">
                                <span>Estado (facturación)<span class="important-data">*</span></span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Ciudad (facturación)</p>
                            <p class="text-information">
                                {{ oTaxAddressOrigin.sCityName }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oTaxAddress.sCityName"
                            :disabled="!(oTaxAddressState.sStateId !== null)" label="Ciudad (facturación)"
                            placeholder="Ciudad (facturación)..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined @keyup="validateFormCustomer()" maxlength="40">
                            <template slot="label">
                                <span>Ciudad (facturación)<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <div v-if="!bModify">
                            <p class="text-title">Código postal (facturación)</p>
                            <p class="text-information">
                                {{ oTaxAddressOrigin.sZIPCode }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oTaxAddress.sZIPCode" :disabled="
                            !(
                                oTaxAddress.sCityName.trim() !== '' &&
                                oTaxAddressState.sStateId !== null
                            )
                        " label="Código postal(facturación)" placeholder="Código postal (facturación)..."
                            class="global-text-field" color="var(--primary-color-border-input)"
                            background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="5"
                            @keyup="validateFormCustomer()" @keypress="fieldNumber($event)"
                            @paste="noLetterZipCodeTax()">
                            <template slot="label">
                                <span>Código postal (facturación)<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <div v-if="!bModify">
                            <p class="text-title">Dirección de facturación de la empresa</p>
                            <p class="text-information">
                                {{ oTaxAddressOrigin.sAddress }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oTaxAddress.sAddress"
                            label="Dirección de facturación de la empresa" placeholder="Ej: Privada San Pedro..."
                            class="global-text-field" color="var(--primary-color-border-input)"
                            background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="255"
                            @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Dirección de facturación de la empresa<span class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <div v-if="!bModify">
                            <p class="text-title">Especificación de dirección de facturación</p>
                            <p class="text-information">
                                {{ oTaxAddressOrigin.sOptionalAddress }}
                            </p>
                        </div>
                        <v-text-field v-else v-model="oTaxAddress.sOptionalAddress"
                            label="Especificación de dirección de facturación"
                            placeholder="Ej: Piso 4, Loft 1, casa color amarillo..." class="global-text-field"
                            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
                            persistent-placeholder outlined maxlength="255" @keyup="validateFormCustomer()">
                            <template slot="label">
                                <span>Especificación de dirección de facturación<span
                                        class="important-data">*</span></span>
                            </template>
                        </v-text-field>
                    </v-col>


                </v-row>
            </v-container>
            <v-divider class="divider-global mt-9 mb-9"></v-divider>
        </div>
        <!-- #endregion INFORMACION FISCAL  -->

        <!-- #region ACCIONES DE BOTONES-->
        <div class="content-btns-accions" v-show="bAdminCustomer">
            <!-- #region BOTON DE ELIMINAR -->
            <div class="content-btn-second">
                <v-btn v-if="bModify" class="global-btn-second-red" @click="
                    deleteItem(oCustomer.sPublicName)
                ">
                    Eliminar
                </v-btn>
            </div>
            <!-- #endregion -->

            <v-spacer></v-spacer>
            <!-- #region BOTON DE DESCARTAR CAMBIOS-->
            <div class="content-btn-second">
                <v-btn v-if="bModify" class="global-btn-neutral" @click="discardChangeCustomer()">
                    Descartar cambios
                </v-btn>
            </div>
            <!-- #endregion -->

            <!-- #region BOTONES DE MODIFICAR Y GUARDAR CAMBIOS-->
            <div v-show="bAdminCustomer" class="content-btn-primary">
                <v-btn v-if="!bModify" class="global-btn-primary" @click="bModify = !bModify">
                    Modificar
                </v-btn>
                <v-btn v-else class="global-btn-primary btn-save-change" :disabled="bUpdateCustomer" :loading="bLoading"
                    @click="changeCustomer()">
                    Guardar cambios
                </v-btn>
            </div>
            <!-- #endregion -->
        </div>
        <!-- #endregion -->
        <!-- #endregion CUSTOMER -->

        <delete-component-global @setDialogDelete="deleteItem" :oDialogDelete="oDialogDelete"
            :bDialogDelete="bDialogDelete" />
    </div>
</template>
<script>
export default {
    props: {
        iTab: Number,
        bAdminCustomer:false
    },
    data() {
        return {
            bLoading: false,
            bSkeleton: true,
            screenWidth: 0,
            screenHeight: 0,
            heightMax: 0,
            bModify: false,
            emptyDataAddress: "Sin información.",
            bUpdateCustomer: false,
            bLoading: false,
            aItemsCountryPhone: [
                { value: "52", text: "+52", icono: "" },
                { value: "52", text: "+52", icono: "" },
            ],
            sCountry: null,
            sCountryOrigin: null,
            sCountryTax: null,
            sCountryTaxOrigin: null,
            aItemsCountry: [],
            aItemsStates: [],
            aItemsStatesTax: [],
            bDisabledState: true,
            bDisabledStateTax: true,

            //#region general information customer ORIGIN
            oCustomerOrigin: {
                //Datos informativos
                sPublicName: "",
                sEmail: "",
                sCountryCallingCode: "52",
                sAreaCallingCode: "",
                sPhoneNumber: "",
                sPhoneExtension: "",
                sLocationStateId: null,
                sLocationCityName: "",
                sLocationZIPCode: "",
                sLocationAddress: "",
                sOptionalLocationAddress: "",

                ///Responsable de la empresa
                sManagerFullName: "",
                sManagerEmail: "",
                sManagerCountryCallingCode: "52",
                sManagerAreaCallingCode: "",
                sManagerPhoneNumber: "",
                sManagerPhoneExtension: "",



                //Informacion de faturacion
                sBusinessName: "",
                sTaxEmail: "",
                sTaxId: "",
                sTaxLocationStateId: null,
                sTaxLocationCityName: "",
                sTaxLocationZIPCode: "",
                sTaxLocationAddress: "",
                sOptionalTaxLocationAddress: "",

            },
            oCustomerInformationOrigin: {},
            oCustomerAddressOrigin: {},
            oCustomerAddressCountryOrigin: {},
            oCustomerAdrressStateOrigin: {},
            oCustomerContactOrigin: {},

            oManagerInformationOrigin: {},
            oManagerContactOrigin: {},

            oTaxInformationOrigin: {},
            oTaxAddressOrigin: {},
            oTaxAddressCountryOrigin: {},
            oTaxAddressStateOrigin: {},
            //#endregion general information customer ORIGIN


            //#region general information customer
            oCustomer: {
                //Datos informativos
                sPublicName: "",
                sEmail: "",
                sCountryCallingCode: "52",
                sAreaCallingCode: "",
                sPhoneNumber: "",
                sPhoneExtension: "",
                sLocationStateId: null,
                sLocationCityName: "",
                sLocationZIPCode: "",
                sLocationAddress: "",
                sOptionalLocationAddress: "",

                ///Responsable de la empresa
                sManagerFullName: "",
                sManagerEmail: "",
                sManagerCountryCallingCode: "52",
                sManagerAreaCallingCode: "",
                sManagerPhoneNumber: "",
                sManagerPhoneExtension: "",



                //Informacion de faturacion
                sBusinessName: "",
                sTaxEmail: "",
                sTaxId: "",
                sTaxLocationStateId: null,
                sTaxLocationCityName: "",
                sTaxLocationZIPCode: "",
                sTaxLocationAddress: "",
                sOptionalTaxLocationAddress: "",

            },
            oCustomerInformation: {},
            oCustomerAddress: {},
            oCustomerAddressCountry: {},
            oCustomerAddressState: {},
            oCustomerContact: {},

            oManagerInformation: {},
            oManagerContact: {},

            oTaxInformation: {},
            oTaxAddress: {},
            oTaxAddressCountry: {},
            oTaxAddressState: {},
            //#endregion general information customer

            bDialogDelete: false,
            oDialogDelete: {
                active: false,
                textTitle: "",
                textDescription: "",
                textQuestion: "",
                api: "",
                returnToView: true,
            },
        };
    },
    beforeMount() {
        this.$store
            .dispatch("getPermissionsByUserGlobal")
            .then((resp) => {
                this.getCustomerById();
                this.getCountires();
                this.$store.commit("setEmptyRawMaterials")

            })
            .catch((err) => {
                this.Error(err);
            });
    },
    updated() {
        this.handleResize();
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        //#region RESIZE PAGE
        matchHeight() {
            this.$nextTick((e) => {
                if (this.$refs.iHeaderDetail !== undefined) {
                    this.heightMax = this.$refs.iHeaderDetail.clientHeight;
                    this.heightMax = this.heightMax + 123; //123 IS NAVBAR
                }
                if (window.innerWidth > 600) {
                    this.screenHeight = window.innerHeight - this.heightMax;
                } else {
                    this.screenHeight = window.innerHeight - this.heightMax;
                }
            });
        },
        handleResize: function () {
            this.matchHeight();
        },
        //#endregion RESIZE PAGE

        //#region FUNCTIONS DYNAMIC
        fieldNumber(evt) {
            if (
                (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
                evt.which > 57
            ) {
                evt.preventDefault();
            }
        },
        noLetterZipCodeInformation() {
            setTimeout(() => {
                const regexExp = /^([0-9])+$/;
                let result = regexExp.exec(this.oCustomer.sLocationZIPCode);
                if (result == null) {
                    this.oCustomer.sLocationZIPCode = "";
                } else {
                    this.oCustomer.sLocationZIPCode =
                        this.oCustomer.sLocationZIPCode;
                }
            }, 100);
        },
        noLetterZipCodeTax() {
            setTimeout(() => {
                const regexExp = /^([0-9])+$/;
                let result = regexExp.exec(this.oCustomer.sTaxLocationZIPCode);
                if (result == null) {
                    this.oCustomer.sTaxLocationZIPCode = "";
                } else {
                    this.oCustomer.sTaxLocationZIPCode =
                        this.oCustomer.sTaxLocationZIPCode;
                }
            }, 100);
        },
        emptyField() {
            this.sCountry = null;
            this.sCountryTax = null;
            this.bLoading = false;
            //Datos informativos
            this.oCustomer.sPublicName = "";
            this.oCustomer.sEmail = "";
            this.oCustomer.sCountryCallingCode = "52";
            this.oCustomer.sAreaCallingCode = "";
            this.oCustomer.sPhoneNumber = "";
            this.oCustomer.sPhoneExtension = "";
            this.oCustomer.sLocationStateId = null;
            this.oCustomer.sLocationCityName = "";
            this.oCustomer.sLocationZIPCode = "";
            this.oCustomer.sLocationAddress = "";
            this.oCustomer.sOptionalLocationAddress = "";

            ///Responsable de la empresa
            this.oCustomer.sManagerFullName = "";
            this.oCustomer.sManagerEmail = "";
            this.oCustomer.sManagerCountryCallingCode = "52";
            this.oCustomer.sManagerAreaCallingCode = "";
            this.oCustomer.sManagerPhoneNumber = "";
            this.oCustomer.sManagerPhoneExtension = "";

            //Informacion de faturacion
            this.oCustomer.sBusinessName = "";
            this.oCustomer.sTaxEmail = "";
            this.oCustomer.sTaxId = "";
            this.oCustomer.sTaxLocationStateId = null;
            this.oCustomer.sTaxLocationCityName = "";
            this.oCustomer.sTaxLocationZIPCode = "";
            this.oCustomer.sTaxLocationAddress = "";
            this.oCustomer.sOptionalTaxLocationAddress = "";

        },
        close() {
            this.$router.push({
                name: "Customer",
                params: {},
            });
            this.bLoading = true;

            this.emptyField();
        },
        parseObj(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        withTrim(val) {
            return val !== null ? val.trim() : val;
        },
        //#endregion FUNCTIONS DYNAMIC

        //#region GET COUNTRIES
        getCountires() {
            DB.get(`${URI}/api/sp/v1/locations/countries`, {
                params: {},
                headers: {
                    Authorization: "Bearer " + this.$store.state.sToken,
                },
            })
                .then((response) => {
                    this.aItemsCountry = response.data.results;
                    this.aItemsCountry.unshift({
                        sCountryId: null,
                        sName: "Seleccione un país",
                        sCode: null,
                    });

                    this.aItemsStates.unshift({
                        sStateId: null,
                        sCountryId: null,
                        sName: "Seleccione un estado",
                        sCode: null,
                    });
                    this.aItemsStatesTax.unshift({
                        sStateId: null,
                        sCountryId: null,
                        sName: "Seleccione un estado",
                        sCode: null,
                    });
                })
                .catch((error) => {
                    this.Error(error.response.data);
                });
        },
        //#endregion GET COUNTRIES

        //#region GET STATES
        getState(id) {
            DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
                params: {},
                headers: {
                    Authorization: "Bearer " + this.$store.state.sToken,
                },
            })
                .then((response) => {
                    // this.sCountry.sCountryId = null;
                    this.aItemsStates = response.data.results;
                    this.aItemsStates.unshift({
                        sStateId: null,
                        sCountryId: null,
                        sName: "Seleccione un estado",
                        sCode: null,
                    });
                    this.validateFormCustomer();
                    this.bDisabledState = false;
                })
                .catch((error) => {
                    this.Error(error.response.data);
                });
        },
        getStateTax(id) {
            DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
                params: {},
                headers: {
                    Authorization: "Bearer " + this.$store.state.sToken,
                },
            })
                .then((response) => {
                    // this.oCustomer.sTaxLocationStateId = null;
                    this.aItemsStatesTax = response.data.results;
                    this.aItemsStatesTax.unshift({
                        sStateId: null,
                        sCountryId: null,
                        sName: "Seleccione un estado",
                        sCode: null,
                    });
                    this.validateFormCustomer();
                    this.bDisabledStateTax = false;
                })
                .catch((error) => {
                    this.Error(error.response.data);
                });
        },
        //#endregion GET STATES

        //#region GET CUSTOMER
        getCustomerById() {
            const payload = {},
                config = {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.sToken,
                        contentType: "application/x-www-form-urlencoded;charset=utf-8",
                    },
                };
            DB.get(
                `${URI}/api/sp/v1/customers/${this.$route.params.id}`,
                config,
                payload
            )
                .then((response) => {
                    this.bModify = false;
                    this.fillDataCustomer(response.data.results);
                    this.bUpdateCustomer = true;
                    this.bSkeleton = false;
                    this.$store.commit("refresher", false);
                })
                .catch((error) => {
                    this.Error(error.response.data);
                });
        },
        //#endregion GET PROVIDER

        //#region FILL DATA CUSTOMER
        fillDataCustomer(results) {
            //FILL IN PROP EDIT

            this.oCustomer = results;

            this.oCustomerInformation = results.oCustomerInformation
            this.oCustomerAddress = results.oCustomerInformation.oCustomerAddress
            this.oCustomerAddressCountry = results.oCustomerInformation.oCustomerAddress.oCountry;
            this.sCountry = this.oCustomerAddressCountry.sCountryId;

            this.oCustomerAddressState = results.oCustomerInformation.oCustomerAddress.oState;
            this.oCustomerContact = results.oCustomerInformation.oCustomerContact

            this.oManagerInformation = results.oManagerInformation;
            this.oManagerContact =
                results.oManagerInformation.oManagerContact;

            this.oTaxInformation = results.oTaxInformation;
            this.oTaxAddress = results.oTaxInformation.oTaxAddress;
            this.oTaxAddressCountry = results.oTaxInformation.oTaxAddress.oCountry;
            this.sCountryTax = this.oTaxAddressCountry.sCountryId;
            this.oTaxAddressState = results.oTaxInformation.oTaxAddress.oState;


            //#region FILL IN PROP ORIGIN
            this.oCustomerOrigin = JSON.parse(JSON.stringify(this.oCustomer));

            this.oCustomerInformationOrigin = this.parseObj(results.oCustomerInformation);
            this.oCustomerAddressOrigin = this.parseObj(results.oCustomerInformation.oCustomerAddress);
            this.oCustomerAddressCountryOrigin = this.parseObj(results.oCustomerInformation.oCustomerAddress.oCountry);
            this.sCountryOrigin = this.oCustomerAddressCountryOrigin.sCountryId;

            this.oCustomerAdrressStateOrigin = this.parseObj(results.oCustomerInformation.oCustomerAddress.oState);
            this.oCustomerContactOrigin = this.parseObj(results.oCustomerInformation.oCustomerContact);

            this.oManagerInformationOrigin = this.parseObj(results.oManagerInformation);
            this.oManagerContactOrigin =
                this.parseObj(results.oManagerInformation.oManagerContact);

            this.oTaxInformationOrigin = this.parseObj(results.oTaxInformation);
            this.oTaxAddressOrigin = this.parseObj(results.oTaxInformation.oTaxAddress);
            this.oTaxAddressCountryOrigin = this.parseObj(results.oTaxInformation.oTaxAddress.oCountry);
            this.sCountryTaxOrigin = this.oTaxAddressCountryOrigin.sCountryId;
            this.oTaxAddressStateOrigin = this.parseObj(results.oTaxInformation.oTaxAddress.oState);
            //#endregion FILL IN PROP ORIGIN


            // this.oTaxInfoState = results.oTaxInfo.oLocationInfo.oState;
            // this.oTaxInfoCountry =
            //     results.oTaxInfo.oLocationInfo.oCountry;
            // this.sCountryTax = this.oTaxInfoCountry.sCountryId;

            // this.oGeneralInfo = results.oGeneralInfo;
            // this.oGeneralContactInfo =
            //     results.oGeneralInfo.oContactInfo;
            // this.oGeneralLocationInfo =
            //     results.oGeneralInfo.oLocationInfo;
            // this.oGeneralState =
            //     results.oGeneralInfo.oLocationInfo.oState;
            // this.oGeneralCountry =
            //     results.oGeneralInfo.oLocationInfo.oCountry;

            //FILL IN PROP ORIGIN
            // this.oCustomerOrigin = JSON.parse(JSON.stringify(this.oEnterprises));

            // this.oManagerInfoOrigin = this.parseObj(
            //     results.oManagerInfo
            // );
            // this.oManagerInfoContactInfoOrigin = this.parseObj(
            //     results.oManagerInfo.oContactInfo
            // );

            // this.oTaxInfoOrigin = this.parseObj(results.oTaxInfo);
            // this.oTaxInfoLocationInfoOrigin = this.parseObj(
            //     results.oTaxInfo.oLocationInfo
            // );
            // this.oTaxInfoStateOrigin = this.parseObj(
            //     results.oTaxInfo.oLocationInfo.oState
            // );
            // this.oTaxInfoCountryOrigin = this.parseObj(
            //     results.oTaxInfo.oLocationInfo.oCountry
            // );
            // this.sCountryTaxOrigin = this.oTaxInfoCountryOrigin.sCountryId;

            // this.oGeneralInfoOrigin = this.parseObj(
            //     results.oGeneralInfo
            // );
            // this.oGeneralContactInfoOrigin = this.parseObj(
            //     results.oGeneralInfo.oContactInfo
            // );
            // this.oGeneralLocationInfoOrigin = this.parseObj(
            //     results.oGeneralInfo.oLocationInfo
            // );
            // this.oGeneralStateOrigin = this.parseObj(
            //     results.oGeneralInfo.oLocationInfo.oState
            // );
            // this.oGeneralCountryOrigin = this.parseObj(
            //     results.oGeneralInfo.oLocationInfo.oCountry
            // );
            // this.sCountryOrigin = this.oGeneralCountryOrigin.sCountryId;

            this.$store.commit("setsNameDetail", "" + this.oCustomerOrigin.sPublicName );
        },
        //#endregion FILL DATA CUSTOMER

        //#region UPDATE CUSTOMER
        updateCustomer() {
            const payload = {
                sPublicName: this.oCustomer.sPublicName,
                // sEmail: this.oGeneralInfo.sEmail,
                sCountryCallingCode: this.oCustomerContact.sCountryCallingCode,
                sAreaCallingCode: this.oCustomerContact.sAreaCallingCode,
                sPhoneNumber: this.oCustomerContact.sPhoneNumber,
                sPhoneExtension: this.oCustomerContact.sPhoneExtension,
                sLocationStateId: this.oCustomerAddressState.sStateId,
                sLocationCityName: this.oCustomerAddress.sCityName,
                sLocationZIPCode: this.oCustomerAddress.sZIPCode,
                sLocationAddress: this.oCustomerAddress.sAddress,
                sOptionalLocationAddress: this.oCustomerAddress.sOptionalAddress,

                sManagerFullName: this.oManagerInformation.sFullName,
                sManagerEmail: this.oManagerInformation.sEmail,
                sManagerCountryCallingCode:
                    this.oManagerContact.sCountryCallingCode,
                sManagerAreaCallingCode:
                    this.oManagerContact.sAreaCallingCode,
                sManagerPhoneNumber: this.oManagerContact.sPhoneNumber,
                sManagerPhoneExtension: this.oManagerContact.sPhoneExtension,

                sBusinessName: this.oTaxInformation.sBusinessName,
                sTaxEmail: this.oTaxInformation.sTaxEmail,
                sTaxId: this.oTaxInformation.sTaxId,
                sTaxLocationStateId: this.oTaxAddressState.sStateId,
                sTaxLocationCityName: this.oTaxAddress.sCityName,
                sTaxLocationZIPCode: this.oTaxAddress.sZIPCode,
                sTaxLocationAddress: this.oTaxAddress.sAddress,
                sOptionalTaxLocationAddress: this.oTaxAddress.sOptionalAddress,
            },
                config = {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.sToken,
                        contentType: "application/x-www-form-urlencoded;charset=utf-8",
                    },
                };
            DB.put(
                `${URI}/api/sp/v1/customers/${this.$route.params.id}`,
                payload,
                config
            )
                .then((response) => {
                    this.Success(response.data.message);
                    this.getCustomerById();
                    this.bLoading = false;
                })
                .catch((error) => {
                    this.bLoading = false;
                    this.Error(error.response.data);
                });
        },
        changeCustomer() {
            this.bLoading = true;
            this.$store
                .dispatch("getPermissionsByUserGlobal")
                .then((resp) => {
                    if (this.bAdminCustomer) {
                        this.updateCustomer();
                    } else {
                        this.Error(this.$store.state.oError403);
                        this.bLoading = false;
                        this.$router
                            .push({
                                name: "Provider",
                                params: {},
                            })
                            .catch(() => { });
                    }
                })
                .catch((err) => {
                    this.Error(err);
                });
        },
        //#endregion UPDATE CUSTOMER

        //#region DELETE PROVIDER OR PRESON
        deleteItem(sName) {
            if (sName) {
                this.oDialogDelete.textTitle = "Eliminar cliente";
                this.oDialogDelete.textDescription = "La eliminación de un clientees una acción irreversible.";
                this.oDialogDelete.textQuestion =
                    "Acepto la responsabilidad y deseo proseguir con la eliminación del cliente " +
                    sName +
                    ".";
                this.oDialogDelete.api = `${URI}/api/sp/v1/customers/${this.$route.params.id}`;
            }
            this.bDialogDelete = !this.bDialogDelete;
        },
        //#endregion DELETE PROVIDER OR PRESON

        //#region DISCARD CHANGES CUSTOMER 
        discardChangeCustomer() {
            this.bModify = false;

            this.oCustomer = JSON.parse(JSON.stringify(this.oCustomerOrigin));

            this.oCustomerInformation = this.parseObj(this.oCustomerInformationOrigin);
            this.oCustomerAddress = this.parseObj(this.oCustomerAddressOrigin);
            this.oCustomerAddressCountry = this.parseObj(this.oCustomerAddressCountryOrigin);
            this.sCountry = this.sCountryOrigin;
            this.getState(this.sCountry);
            this.oCustomerAddressState = this.parseObj(this.oCustomerAdrressStateOrigin);
            this.oCustomerContact = this.parseObj(this.oCustomerContactOrigin);

            this.oManagerInformation = this.parseObj(this.oManagerInformationOrigin);
            this.oManagerContact =
                this.parseObj(this.oManagerContactOrigin);

            this.oTaxInformation = this.parseObj(this.oTaxInformationOrigin);
            this.oTaxAddress = this.parseObj(this.oTaxAddressOrigin);
            this.oTaxAddressCountry = this.parseObj(this.oTaxAddressCountryOrigin);
            this.sCountryTax = this.sCountryTaxOrigin;
            this.getStateTax(this.sCountryTax);

            this.oTaxAddressState = this.parseObj(this.oTaxAddressStateOrigin);

            // this.oEnterprises = this.parseObj(this.oCustomerOrigin);

            // this.oManagerInfo = this.parseObj(this.oManagerInfoOrigin);
            // this.oManagerInfoContactInfo = this.parseObj(
            //     this.oManagerInfoContactInfoOrigin
            // );

            // this.oTaxInfo = this.parseObj(this.oTaxInfoOrigin);
            // this.oTaxInfoLocationInfo = this.parseObj(
            //     this.oTaxInfoLocationInfoOrigin
            // );
            // this.oTaxInfoState = this.parseObj(this.oTaxInfoStateOrigin);
            // this.oTaxInfoCountry = this.parseObj(this.oTaxInfoCountryOrigin);
            // this.sCountryTax = this.sCountryTaxOrigin;

            // this.oGeneralInfo = this.parseObj(this.oGeneralInfoOrigin);
            // this.oGeneralContactInfo = this.parseObj(this.oGeneralContactInfoOrigin);
            // this.oGeneralLocationInfo = this.parseObj(
            //     this.oGeneralLocationInfoOrigin
            // );
            // this.oGeneralState = this.parseObj(this.oGeneralStateOrigin);
            // this.oGeneralCountry = this.parseObj(this.oGeneralCountryOrigin);
            // this.sCountry = this.sCountryOrigin;


            this.bUpdateCustomer = true
        },
        //#endregion DISCARD CHANGES CUSTOMER 

        //#region UPDATE DATE PHONE ENTERPISE (ENTERPISE SECTION)
        updateCountryCallingCode(val) {
            this.oCustomerContact.sCountryCallingCode = val;
            this.validateFormCustomer();
        },
        updateAreaCallingCode(val) {
            this.oCustomerContact.sAreaCallingCode = val;
            this.validateFormCustomer();
        },
        updatePhoneNumber(val) {
            this.oCustomerContact.sPhoneNumber = val;
            this.validateFormCustomer();
        },
        updatePhoneExtension(val) {
            this.oCustomerContact.sPhoneExtension = val;
            this.validateFormCustomer();
        },
        //#endregion UPDATE DATE PHONE ENTERPISE (ENTERPISE SECTION)

        //#region UPDATE DATE PHONE MANAGER (ENTERPISE SECTION)
        updateManagerCountryCallingCode(val) {
            this.oManagerContact.sCountryCallingCode = val;
            this.validateFormCustomer();
        },
        updateManagerAreaCallingCode(val) {
            this.oManagerContact.sAreaCallingCode = val;
            this.validateFormCustomer();
        },
        updateManagerPhoneNumber(val) {
            this.oManagerContact.sPhoneNumber = val;
            this.validateFormCustomer();
        },
        updateManagerPhoneExtension(val) {
            this.oManagerContact.sPhoneExtension = val;
            this.validateFormCustomer();
        },
        //#endregion UPDATE DATE PHONE MANAGER (ENTERPISE SECTION)

        //#region VALIDATE FORM CUSTOMER
        validateFormCustomer() {
            this.bUpdateCustomer =
                (this.oCustomer.sPublicName == "" ||
                    this.oCustomer.sPublicName ==
                    this.oCustomerOrigin.sPublicName) &&
                (this.oCustomer.sEmail == "" ||
                    this.oCustomer.sEmail ==
                    this.oCustomerOrigin.sEmail) &&
                (this.oCustomerContact.sCountryCallingCode == "" ||
                    this.oCustomerContact.sCountryCallingCode ==
                    this.oCustomerContactOrigin.sCountryCallingCode) &&
                (this.oCustomerContact.sAreaCallingCode == "" ||
                    this.oCustomerContact.sAreaCallingCode ==
                    this.oCustomerContactOrigin.sAreaCallingCode) &&
                (this.oCustomerContact.sPhoneNumber == "" ||
                    this.oCustomerContact.sPhoneNumber ==
                    this.oCustomerContactOrigin.sPhoneNumber) &&
                (this.oCustomerContact.sPhoneExtension == "" ||
                    this.oCustomerContact.sPhoneExtension ==
                    this.oCustomerContactOrigin.sPhoneExtension) &&
                (this.oCustomerAddress.sAddress == "" ||
                    this.oCustomerAddress.sAddress ==
                    this.oCustomerAddressOrigin.sAddress) &&
                (this.oCustomerAddress.sOptionalAddress == "" ||
                    this.oCustomerAddress.sOptionalAddress ==
                    this.oCustomerAddressOrigin.sOptionalAddress) &&
                (this.sCountry == "" ||
                    this.sCountry ==
                    this.sCountryOrigin) &&
                (this.oCustomerAddressState.sStateId == "" ||
                    this.oCustomerAddressState.sStateId ==
                    this.oCustomerAdrressStateOrigin.sStateId) &&
                (this.oCustomerAddress.sCityName == "" ||
                    this.oCustomerAddress.sCityName ==
                    this.oCustomerAddressOrigin.sCityName) &&
                (this.oCustomerAddress.sZIPCode == "" ||
                    this.oCustomerAddress.sZIPCode ==
                    this.oCustomerAddressOrigin.sZIPCode) &&
                (this.oManagerInformation.sFullName == "" ||
                    this.oManagerInformation.sFullName ==
                    this.oManagerInformationOrigin.sFullName) &&
                (this.oManagerInformation.sEmail == "" ||
                    this.oManagerInformation.sEmail ==
                    this.oManagerInformationOrigin.sEmail) &&
                (this.oManagerContact.sCountryCallingCode == "" ||
                    this.oManagerContact.sCountryCallingCode ==
                    this.oManagerContactOrigin.sCountryCallingCode) &&
                (this.oManagerContact.sAreaCallingCode == "" ||
                    this.oManagerContact.sAreaCallingCode ==
                    this.oManagerContactOrigin.sAreaCallingCode) &&
                (this.oManagerContact.sPhoneNumber == "" ||
                    this.oManagerContact.sPhoneNumber ==
                    this.oManagerContactOrigin.sPhoneNumber) &&
                (this.oManagerContact.sPhoneExtension == "" ||
                    this.oManagerContact.sPhoneExtension ==
                    this.oManagerContactOrigin.sPhoneExtension) &&
                (this.oTaxInformation.sBusinessName == "" ||
                    this.oTaxInformation.sBusinessName ==
                    this.oTaxInformationOrigin.sBusinessName) &&
                (this.oTaxInformation.sTaxEmail == "" ||
                    this.oTaxInformation.sTaxEmail ==
                    this.oTaxInformationOrigin.sTaxEmail) &&
                (this.oTaxInformation.sTaxId == "" ||
                    this.oTaxInformation.sTaxId ==
                    this.oTaxInformationOrigin.sTaxId) &&
                (this.oTaxAddress.sAddress == "" ||
                    this.oTaxAddress.sAddress ==
                    this.oTaxAddressOrigin.sAddress) &&
                (this.oTaxAddress.sOptionalAddress == "" ||
                    this.oTaxAddress.sOptionalAddress ==
                    this.oTaxAddressOrigin.sOptionalAddress) &&
                (this.sCountryTax == "" ||
                    this.sCountryTax ==
                    this.sCountryTaxOrigin) &&
                (this.oTaxAddressState.sStateId == "" ||
                    this.oTaxAddressState.sStateId ==
                    this.oTaxAddressStateOrigin.sStateId) &&
                (this.oTaxAddress.sCityName == "" ||
                    this.oTaxAddress.sCityName ==
                    this.oTaxAddressOrigin.sCityName) &&
                (this.oTaxAddress.sZIPCode == "" ||
                    this.oTaxAddress.sZIPCode ==
                    this.oTaxAddressOrigin.sZIPCode)

        },
        changeCountry() {
            this.bDisabledState = true;
            this.oCustomerAddressState.sStateId = null;
            this.oCustomerAddress.sCityName = "";
            this.oCustomerAddress.sZIPCode = "";
            this.aItemsStates = [];
        },
        changeState() {
            this.oCustomerAddress.sCityName = "";
            this.oCustomerAddress.sZIPCode = "";
        },
        changeCountryTax() {
            this.bDisabledStateTax = true;
            this.oTaxAddressState.sStateId = null;
            this.oTaxAddress.sCityName = "";
            this.oTaxAddress.sZIPCode = "";
            this.aItemsStatesTax = [];
        },
        changeStateTax() {
            this.oTaxAddress.sCityName = "";
            this.oTaxAddress.sZIPCode = "";
        }
        //#endregion VALIDATE FORM CUSTOMER


    },
    computed: {
    
    },
    watch: {
        sCountry() {
            if (this.sCountry !== null) {
                this.getState(this.sCountry);
            } else {
                this.aItemsStates.unshift({
                    sStateId: null,
                    sCountryId: null,
                    sName: "Seleccione un estado",
                    sCode: null,
                });
                this.validateFormCustomer();
            }
        },
        sCountryTax() {

            if (this.sCountryTax !== null) {
                this.getStateTax(this.sCountryTax);
            } else {
                this.aItemsStatesTax.unshift({
                    sStateId: null,
                    sCountryId: null,
                    sName: "Seleccione un estado",
                    sCode: null,
                });
                this.validateFormCustomer();
            }
        },
        iTab() {
            if (this.iTab === 0) {
                this.$store
                    .dispatch("getPermissionsByUserGlobal")
                    .then((resp) => {
                        this.getCustomerById();
                        this.getCountires();
                        // this.getCountries();
                    })
                    .catch((err) => {
                        this.Error(err);
                    });
            }
        },
    },
};
</script>
<style scoped>
.content-title-add-provider {
    width: 100%;
}

.title-add-provider {
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
    font-size: 25px;
    text-align: center;
}

.content-carousel-1 {
    position: relative;
    /* height: 170px; */
    width: 100%;
    /* border: var(--primary-color-menu) dashed; */
    border-radius: 20px;
    justify-content: center;
    display: flex;
}

.content-carousel-2 {
    position: relative;
    /* height: 170px; */
    width: 100%;
    /* border: var(--primary-color-menu) dashed; */
    border-radius: 20px;
    justify-content: center;
    display: flex;
}

.content-icon-carousel {
    z-index: 9;
    position: absolute;
    display: flex;
    bottom: 10px;
    /* background-color: var(--primary-color-text-yellow); */
}

.content-icon-1 {
    cursor: pointer;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background-color: white;
}

.content-icon-2 {
    cursor: pointer;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin-left: 10px;
    background-color: white;
}

.text-img-add {
    color: var(--primary-color-text) !important;
    font-family: "pop-Semibold";
    font-size: 9px;
    text-transform: initial;
}

.content-image {
    width: 100%;
    height: 180px !important;
    position: relative;
    border: 1px solid #70707080;
    border-radius: 20px;
}

.content-image img {
    width: 100%;
    height: auto;
}

@supports (object-fit: cover) {
    .content-image img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 20px;
    }
}

.button-delete-item {
    position: absolute;
    right: 5px;
    top: 5px;
    min-width: 40px !important;
    height: 40px !important;
    background-color: var(--primary-color-btn-neutral-red) !important;
    padding: 0px !important;
    border-radius: 10px;
}

.button-add-img {
    width: 100% !important;
    height: 180px !important;
    background: transparent 0% 0% no-repeat padding-box !important;
    border: 2px dashed var(--primary-color-divider);
    border-radius: 20px;
    opacity: 1;
}

.content-arrows-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.text-identification {
    margin-bottom: 0px;
    color: var(--primary-color-text);
}

.content-text-informacion-fiscal {
    letter-spacing: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    font-family: "pop-Bold";
    color: var(--primary-color-color-title-information);
}

.content-text-informacion-manager {
    letter-spacing: 4.8px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    font-family: "pop-Regular";
    color: var(--primary-color-color-title-information);
}

.content-btns-accions {
    display: flex;
    width: 100% !important;
    justify-content: flex-end;
    padding: 0px 0px 0px 12px;
    margin-top: 25px;
    margin-bottom: 5px;
}

.content-btn-primary {
    width: 200px;
}

.content-btn-second {
    width: 200px;
    margin-right: 15px;
}

.text-title {
    margin-bottom: 2px;
    color: var(--primary-color-text-title-detail);
    font-family: "pop-Regular";
    opacity: 1;
    letter-spacing: 0px;
}

.text-information {
    margin-bottom: 5px;
    color: var(--primary-color-text);
    font-family: "pop-Regular";
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .content-btns-accions {
        display: block;
        width: 100% !important;
    }

    .content-btn-second {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
    }

    .content-btn-primary {
        width: 100%;
    }

    .text-img-add {
        color: var(--primary-color-text) !important;
        font-family: "pop-Semibold";
        font-size: 6px;
        text-transform: initial;
    }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }

    .text-img-add {
        color: var(--primary-color-text) !important;
        font-family: "pop-Semibold";
        font-size: 6px;
        text-transform: initial;
    }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/* XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}
</style>